import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import closeIcon from "../../../images/white-close.png";
import Labels from "../../Vacancies/Labels";

export default function TenancySlider(props) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  // onKeyPress esc setOpenModal to null
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setOpenModal(null);
      }
    };
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  return (
    <>
      <div className={props.classes.TenancySlider}>
        <Slider
          asNavFor={nav2}
          ref={slider1Ref}
          arrows={false}
          dots={true}
          autoplay={true}
          autoplaySpeed={5000}
        >
          {props.images.map((slide, index) => {
            return (
              <div className="image-slide">
                <button
                  onClick={() => {
                    setOpenModal(slide.image);
                  }}
                >
                  <img src={slide.image} alt="" />
                </button>
              </div>
            );
          })}
        </Slider>
        <Slider
          asNavFor={nav1}
          ref={slider2Ref}
          slidesToShow={3}
          swipeToSlide={true}
          focusOnSelect={true}
          dots={false}
          infinite={false}
          className="slider-nav"
        >
          {props.images.map((image) => {
            return (
              <div>
                <img src={image.image} alt="" />
              </div>
            );
          })}
        </Slider>
        {props.children !== null && (
          <div className={props.classes.CardInfo}>
            <Labels status={props.status} featured={props.featured}></Labels>
          </div>
        )}
      </div>
      {props.images.map((slide, index) => {
        const firstImage = props.images[0];
        const lastImage = props.images[props.images.length - 1];
        const currentImage = props.images[index];
        const nextImage =
          currentImage === lastImage
            ? firstImage.image
            : props.images[index + 1].image;
        const prevImage =
          currentImage === firstImage
            ? lastImage.image
            : props.images[index - 1].image;

        return (
          <div
            className="modal"
            data-open={openModal === slide.image ? true : false}
          >
            <button className="close-modal" onClick={() => setOpenModal("")}>
              <img src={closeIcon} alt="close" />
            </button>
            <img src={slide.image} alt="" />

            {/* next slide */}
            <button
              className="next-slide"
              onClick={() => {
                slider1Ref.current.slickNext();
                setOpenModal(nextImage);
              }}
            >
              Next Slide
            </button>
            <button
              className="prev-slide"
              onClick={() => {
                slider1Ref.current.slickPrev();
                setOpenModal(prevImage);
              }}
            >
              Prev Slide
            </button>
          </div>
        );
      })}
    </>
  );
}
