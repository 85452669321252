import React from "react";
import Grid from "@material-ui/core/Grid";

export default function LoiTab({ loi, turnover }) {
  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={6}>
        <h3>Introduction</h3>
        <p>
          This is a one-off payment made at the start of the vacancy agreement.
          All figures are estimates and exclude VAT.
        </p>
        <div className="breakdown">
          <p>
            4 Week's Rent <span>£{loi.fourWeeksRent}</span>
          </p>
          <p>
            Trading Deposit <span>£{loi.tradingDeposit}</span>
          </p>
          <p>
            Professional Fees <span>£{loi.professionalFeesAndInduction}</span>
          </p>
          <p>
            Stock & Glassware <span>£{loi.stockAndGlassware}</span>
          </p>
        </div>
        <p className="sub-total">
          Sub Total <span>£{loi.subTotal}</span>
        </p>
        <div className="breakdown extra">
          <p>
            Fixtures & Fittings <span>£{loi.fixturesAndFittings}</span>
          </p>
        </div>
        <p className="turnover">
          Potential Turnover <span>£{turnover}</span>
        </p>
      </Grid>

      <Grid item xs={12} md={6}>
        <img src={loi.levelOfInvestmentImage} alt="" />
      </Grid>
    </Grid>
  );
}
