import React from "react";
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from "react-html-parser";
import { transformLink } from "../../../helpers/index";

export default function BarrelageTab({ barrelage }) {
  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={6}>
        <h3>Introduction</h3>
        <p>
          A barrel is a unit of measurement adopted by the pub and brewing
          industry. A barrel is 36 gallons or 288 pints. This excludes wines,
          minerals and spirits.
        </p>
        <div className="breakdown">
          <p>
            FMT Barrelage <span>{barrelage.fmtBarrelage}</span>
          </p>
          <p>
            Current MAT Barrelage <span>{barrelage.currentMatBarrelage}</span>
          </p>
          <p>
            1 Year ago MAT Barrelage{" "}
            <span>{barrelage.oneYearAgoMatBarrelage}</span>
          </p>
          <p>
            2 Year ago MAT Barrelage{" "}
            <span>{barrelage.twoYearAgoMatBarrelage}</span>
          </p>
          <p>
            3 Year ago MAT Barrelage{" "}
            <span>{barrelage.threeYearAgoMatBarrelage}</span>
          </p>
        </div>
        {barrelage.barrelageNote && (
          <p>
            {ReactHtmlParser(barrelage.barrelageNote, {
              transform: transformLink,
            })}
          </p>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <img src={barrelage.barrelageImage} alt="" />
      </Grid>
    </Grid>
  );
}
