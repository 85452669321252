import backArrow from "../../../images/arrow-prev-black.png";
import iconEmail from "../../../images/icon-email.png";
import iconPhone from "../../../images/icon-phone.png";
import iconForm from "../../../images/icon-form.png";
import arrow from "../../../images/right-arrow.png";
import sliderArrow from "../../../images/slider-arrow.png";

export default theme => ({
    root: {
        marginBottom: 100,
        '& .MuiGrid-spacing-xs-10': {
           marginTop: 0,
           marginBottom: 0,
            '& > .MuiGrid-item': {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        '& .go-back': {
            backgroundColor: 'transparent',
            border: 'none',
            color: theme.palette.common.black,
            fontFamily: theme.headerFont,
            fontSize: 14,
            letterSpacing: '0.05em',
            lineHeight: '18px',
            textTransform: 'uppercase',
            padding: 0,
            outline: 'none !important',
            paddingLeft: 28,
            backgroundImage: 'url(' + backArrow + ')',
            backgroundPosition: 'center left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            transition: 'opacity .3s ease-in-out',
            marginTop: 16,
            [theme.breakpoints.up('md')]: {
                marginBottom: 36,
                marginTop: 40,
            },
            '&:hover, &:focus': {
                opacity: 0.8,
            },
        },
        '& h2': {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontFamily: theme.headerFont,
            fontSize: 24,
            marginTop: 30,
            marginBottom: 10,
            lineHeight: 1.25,
            display: 'block',
            textAlign: 'center',
            letterSpacing: '0.056em',
            [theme.breakpoints.up('md')]: {
                fontSize: 32,
                textAlign: 'left',
                marginTop: 0,
            }
        },
        '& p': {
            fontSize: 14,
            letterSpacing: '0.056em',
            lineHeight: 2,
            marginBottom: 50,
            [theme.breakpoints.up('md')]: {
                fontSize: 16,
            }
        },
        '& .ooh': {
            letterSpacing: '0.056em',
            lineHeight: 1.5,
            marginBottom: 24,
            fontStyle: 'italic',
            fontFamily: theme.bodyFont,
            display: 'block',
            textAlign: 'center',
            marginTop: 0,
            fontSize: 16,
            [theme.breakpoints.up('md')]: {
                marginBottom: 60,
                fontSize: 24,
                textAlign: 'left',
            }
        },
        '& .rent, & .turnover': {
            color: theme.palette.primary.main,
            fontSize: 14,
            lineHeight: 1.75,
            letterSpacing: '0.056em',
            margin: 0,
            marginBottom: 16,
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
            },
            '& i': {
                fontSize: 12,
                [theme.breakpoints.up('md')]: {
                    fontSize: 16,
                },
            },
            '& span': {
                fontWeight: 'bold',
                fontSize: 16,
                float: 'right',
                letterSpacing: '0.04em',
                color: theme.palette.common.black,
                fontFamily: theme.headerFont,
                [theme.breakpoints.up('md')]: {
                    fontSize: 24,
                },
            }
        },
        '& .rent': {
            borderBottom: '1px solid ' + theme.palette.common.black,
            paddingBottom: 16,
        },
        '& .interested': {
            marginBottom: 36,
            '&.desktop': {
                display: 'none',
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
                '&.desktop': {
                    display: 'block',
                },
            },
            '& h3': {
                fontSize: 24,
                lineHeight: 1.25,
                textTransform: 'uppercase',
                letterSpacing: '0.04em',
                marginTop: 100,
                marginBottom: 30,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 40,
                    marginTop: 60,
                },
                '&::before': {
                    width: 40,
                    height: 4,
                    content: '""',
                    display: 'block',
                    marginBottom: 16,
                    backgroundColor: theme.palette.primary.main,
                }
            },
            '& a.button': {
                width: 'calc(33.333% - 10px)',
                margin: 0,
                fontFamily: theme.headerFont,
                boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                fontSize: 16,
                lineHeight: 1.2,
                fontWeight: 400,
                textTransform: 'uppercase',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                display: 'inline-flex',
                borderRadius: 4,
                textDecoration: 'none',
                letterSpacing: '0.06em',
                marginRight: 15,
                flexDirection: 'column-reverse',
                minWidth: 0,
                maxWidth: 'calc(100% - 50px)',
                textAlign: 'center',
                padding: '20px 30px',
                alignItems: 'center',
                justifyContent: 'center',
                [theme.breakpoints.up('md')]: {
                    maxWidth: 115,
                    marginRight: 26,
                    width: 'calc(33.333% - 18px)',
                },
                '&::after': {
                    width: 20,
                    height: 20,
                    content: '""',
                    backgroundImage: 'url(' + arrow + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    display: 'inline-block',
                    backgroundPosition: 'center',
                    marginBottom: 10,
                    marginLeft: 0,
                },
                '&.email::after': {
                    backgroundImage: 'url(' + iconEmail + ') !important',
                },
                '&.phone::after': {
                    backgroundImage: 'url(' + iconPhone + ') !important',
                },
                '&.form': {
                    marginRight: 0,
                },
                '&.form::after': {
                    backgroundImage: 'url(' + iconForm + ') !important',
                }
            }
        },
        '& .slick-slider.slider-nav': {
            marginTop: 4,
            paddingLeft: 26,
            paddingRight: 26,
            [theme.breakpoints.up('md')]: {
                paddingLeft: 50,
                paddingRight: 50,
            },
            '& .slick-slide': {
                padding: '0 4px',
            },
            '& .slick-dots': {
                width: 48,
                bottom: 0,
                left: 'calc(50% - 24px)',
                [theme.breakpoints.up('md')]: {
                    bottom: 'auto',
                    top: -44,
                    right: 16,
                    left: 'auto',
                },
                '&::after': {
                    position: 'absolute',
                    left: 24,
                    width: 1,
                    height: 20,
                    backgroundColor: theme.palette.common.black,
                    content: '""',
                    top: 0,
                    transformOrigin: '50% 50%',
                    transform: 'rotate(15deg)',
                    [theme.breakpoints.up('md')]: {
                        backgroundColor: theme.palette.common.white
                    },
                },
                '& li': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    margin: 0,
                    '& button': {
                        '&::before': {
                            display: 'none',
                        },
                        padding: 0,
                        fontSize: 17,
                        fontWeight: 'bold',
                        fontFamily: theme.headerFont,
                        textAlign: 'center',
                        width: 20,
                        color: 'transparent',
                    },
                    '&.slick-active': {
                        '& button': {
                            color: theme.palette.common.black,
                            [theme.breakpoints.up('md')]: {
                                color: theme.palette.common.white
                            },
                        }
                    },
                    '&:last-of-type button': {
                        textShadow: '28px 0 0 ' + theme.palette.common.black,
                        [theme.breakpoints.up('md')]: {
                            textShadow: '28px 0 0 ' + theme.palette.common.white,
                        },
                    }
                }
            },
            '& .slick-next': {
                width: 18,
                height: 18,
                backgroundImage: 'url(' + sliderArrow + ') !important',
                backgroundSize: 'contain !important',
                backgroundPosition: 'center !important',
                backgroundRepeat: 'no-repeat !important',
                right: 0,
                zIndex: 10,
                transform: 'translateY(-50%)',
                [theme.breakpoints.up('md')]: {
                    right: 12
                },
                '&::before': {
                    display: 'none',
                },
                '&.slick-disabled': {
                    opacity: 0.25,
                },
            },
            '& .slick-prev': {
                width: 18,
                height: 18,
                backgroundImage: 'url(' + sliderArrow + ') !important',
                backgroundSize: 'contain !important',
                backgroundPosition: 'center !important',
                backgroundRepeat: 'no-repeat !important',
                left: 0,
                transform: 'translateY(-50%) rotate(180deg)',
                zIndex: 10,
                [theme.breakpoints.up('md')]: {
                    left: 12
                },
                '&::before': {
                    display: 'none',
                },
                '&.slick-disabled': {
                    opacity: 0.25,
                },
            }
        }
    }
});