import React, { Component } from "react";
import Slider from "react-slick";

export default class TenancySlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        return (
            <div>
                <Slider
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    arrows={false}
                >
                    {this.props.images.map((image) => {
                        return <div>
                            <img src={image.image} alt="" />
                        </div>
                    })}
                </Slider>
                <Slider
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={3}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    dots={true}
                    className="slider-nav"
                >
                    {this.props.images.map((image) => {
                        return <div>
                            <img src={image.image} alt=""/>
                        </div>
                    })}
                </Slider>
            </div>
        );
    }
}