import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import ReactHtmlParser from "react-html-parser";
import { transformLink } from "../../../helpers";
import Link from "../../Link";

import iconFood from "../../../../src/images/icon-food.png";
import iconDog from "../../../../src/images/icon-dog-friendly.png";
import iconGarden from "../../../../src/images/icon-beer-garden.png";
import iconAccom from "../../../../src/images/icon-bed.png";

const useStyles = makeStyles((theme) => styles(theme));

export default function TenancyOverview({
  overview,
  ooh,
  downloads,
  crmId,
  facilityAccommodation,
  facilityBeerGarden,
  facilityDogFriendly,
  facilityFood,
}) {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Grid container spacing={10}>
        <Grid item xs={12} className="overview">
          <h2>Overview</h2>
          {ReactHtmlParser(overview, {
            transform: transformLink,
          })}

          <div className="facilities">
            <h3>Facilities</h3>
            {facilityFood && <span><img src={iconFood} alt="" />Food</span>}
            {facilityAccommodation && <span><img src={iconAccom} alt="" />Accommodation</span>}
            {facilityDogFriendly && <span><img src={iconDog} alt="" />Dog friendly</span>}
            {facilityBeerGarden && <span><img src={iconGarden} alt="" />Beer garden</span>}
          </div>

          <div className="downloads">
            {downloads &&
              downloads.map((download) => {
                let href = "";
                if (download.file != null) {
                  href = download.file;
                } else {
                  href = download.externalLink;
                }
                return (
                  <Link className="download-link" to={href}>
                    {download.title}
                  </Link>
                );
              })}
            {crmId && (
              <Link
                className="download-link"
                to={process.env.GATSBY_FRONTEND_URL + "vacancy/pdf/" + crmId}
              >
                Download vacancy PDF
              </Link>
            )}
          </div>
        </Grid>
      </Grid>
    </section>
  );
}
