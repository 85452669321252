import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import Box from "@material-ui/core/Box/Box";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import LocationTab from "./LocationTab";
import AccommodationTab from "./AccommodationTab";
import BarrelageTab from "./BarrelageTab";
import LoiTab from "./LoiTab";
import ChannelsTab from "./ChannelsTab";

const useStyles = makeStyles(theme => styles(theme));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function TenancyTabs({location, accommodation, barrelage, loi, channels}) {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <div className={`tab-content ${classes.root}`}>
        <h2>The Detail</h2>
        <AppBar position="static" color="default">
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label={"Location"} {...a11yProps(0)} />
                <Tab label={"Barrelage"} {...a11yProps(1)} />
                <Tab label={"Level of Investment"} {...a11yProps(2)} />
                {accommodation !==null && 
                    <Tab label={"Accommodation"} {...a11yProps(3)} />
                }
                {accommodation !==null && 
                    <Tab label={"Digital Channels"} {...a11yProps(4)} />
                }
                {accommodation === null && 
                    <Tab label={"Digital Channels"} {...a11yProps(3)} />
                }
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            <LocationTab location={location}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <BarrelageTab barrelage={barrelage}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <LoiTab loi={loi.loi} turnover={loi.turnover}/>
        </TabPanel>
        {accommodation !==null && 
            <>
                <TabPanel value={value} index={3}>
                    <AccommodationTab accommodation={accommodation} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <ChannelsTab channels={channels}/>
                </TabPanel>
            </>
        }
        {accommodation === null && 
            <TabPanel value={value} index={3}>
                <ChannelsTab channels={channels}/>
            </TabPanel>
        }
    </div>
};