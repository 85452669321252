import arrow from "../../../images/right-arrow.png";

export default theme => ({
    root: {
        position: 'relative',
        marginBottom: 36,
        '& > h2': {
            fontWeight: 'bold',
            fontSize: 24,
            letterSpacing: '0.056em',
            lineHeight: 1.25,
            textTransform: 'uppercase',
            marginBottom: 40,
            marginTop: 0,
            '&::before': {
                width: 40,
                height: 4,
                content: '""',
                display: 'block',
                marginBottom: 16,
                backgroundColor: theme.palette.primary.main,
            }
        },
        '& header': {
            zIndex: 1,
            position: 'relative',
            marginBottom: -1,
            width: 'calc(100% + 36px)',
            marginLeft: -18,
            marginRight: -18,
            [theme.breakpoints.up('md')]: {
                width: '100%',
                marginLeft: 0,
                marginRight: 0,
            }
        },
        '& .MuiTabs-scrollButtonsDesktop': {
            display: 'inline-flex !important',
        },
        '& .MuiAppBar-colorDefault': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '& .MuiTab-textColorSecondary': {
                color: theme.palette.primary.main,
                fontSize: 14,
                letterSpacing: '0.04em',
                lineHeight: 1.5,
                padding: '15px 20px',
                maxWidth: 'none',
                width: 'auto',
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                    padding: '16px 25px',
                },
                '&.Mui-selected': {
                    color: theme.palette.secondary.main,
                    backgroundColor: 'white',
                    boxShadow: '0 3px 32px rgba(0,0,0,0.13)',
                }
            },
        },
        '& .MuiTabs-indicator': {
            [theme.breakpoints.up('md')]: {
                height: 3,
            }
        },
        '& .MuiBox-root': {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 50,
            paddingBottom: 100,
            position: 'relative',
            '& > *': {
                zIndex: 2,
                position: 'relative',
            },
            '&::before': {
                position: 'absolute',
                zIndex: 1,
                top: 0,
                bottom: 0,
                width: '100vw',
                left: '50%',
                transform: 'translateX(-50vw)',
                backgroundColor: 'white',
                content: '""',
                borderTop: '1px solid',
                borderColor: theme.palette.secondary.main,
                [theme.breakpoints.up('md')]: {
                    borderTop: '2px solid',
                },
                [theme.breakpoints.up(1300)]: {
                    width: 'calc(100vw - 100px)',
                    transform: 'translateX(-50%)',
                }
            },
            '& h3': {
                fontSize: 16,
                marginTop: 0,
                marginBottom: 24,
                lineHeight: 2,
                letterSpacing: '0.04em',
                textTransform: 'uppercase',
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                }
            },
            '& p': {
                fontSize: 14,
                lineHeight: 2,
                letterSpacing: 0,
                [theme.breakpoints.up('md')]: {
                    fontSize: 16,
                },
                '& i': {
                    display: 'inline-block',
                }
            },
            '& .overview': {
                marginBottom: 40,
                [theme.breakpoints.up('md')]: {
                    columnCount: 2,
                    columnGap: 84,
                }
            },
            '& .location-info': {
                marginTop: 16,
                display: 'block',
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    flexDirection: 'row-reverse',
                },
                '& .address': {
                    backgroundColor: theme.palette.common.black,
                    padding: '50px 30px',
                    [theme.breakpoints.up('md')]: {
                        flex: '0 0 33.333%',
                        maxWidth: '33.333%',
                    },
                    '& h4': {
                        color: theme.palette.primary.main,
                        textTransform: 'uppercase',
                        marginBottom: 22,
                        fontWeight: 'bold',
                        letterSpacing: '0.04em',
                        lineHeight: 1.25,
                        fontSize: 18,
                        marginTop: 0,
                    },
                    '& p': {
                        fontSize: 16,
                        lineHeight: 2,
                        letterSpacing: '0.032em',
                        color: theme.palette.common.white,
                        margin: 0,
                    }
                },
                '& .map': {
                    height: 300,
                    display: 'block',
                    [theme.breakpoints.up('md')]: {
                        flex: '0 0 66.666%',
                        maxWidth: '66.666%',
                    },
                },
            },
            '& .breakdown': {
                marginTop: 40,
                marginBottom: 24,
                '& p': {
                    marginTop: 0,
                    marginBottom: 20,
                    paddingBottom: 16,
                    borderBottom: '1px solid ' + theme.palette.common.black,
                    color: theme.palette.primary.main,
                    fontSize: 14,
                    lineHeight: 1.75,
                    letterSpacing: '0.056em',
                    [theme.breakpoints.up('md')]: {
                        fontSize: 18,
                    },
                    '& span': {
                        fontSize: '120%',
                        fontWeight: 'bold',
                        fontFamily: theme.headerFont,
                        float: 'right',
                        marginLeft: 16,
                        letterSpacing: '0.04em',
                        color: theme.palette.common.black,
                        maxWidth: '50%',
                        textAlign: 'right',
                        lineHeight: 1.2,
                    },
                    '&:after': {
                        content: '""',
                        clear: 'both',
                        display: 'table',
                    }
                },
                '&.extra': {
                    marginTop: 20,
                }
            },
            '& .sub-total': {
                backgroundColor: theme.palette.background.default,
                marginTop: 0,
                marginBottom: 10,
                paddingBottom: 16,
                color: theme.palette.primary.main,
                fontSize: 14,
                lineHeight: 1.75,
                letterSpacing: '0.056em',
                padding: 15,
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                    padding: 24,
                },
                '& span': {
                    fontSize: '120%',
                    fontWeight: 'bold',
                    fontFamily: theme.headerFont,
                    float: 'right',
                    marginLeft: 16,
                    letterSpacing: '0.04em',
                    color: theme.palette.common.black,
                }
            },
            '& .turnover': {
                backgroundColor: theme.palette.common.black,
                marginTop: 0,
                marginBottom: 10,
                paddingBottom: 16,
                color: theme.palette.primary.main,
                fontSize: 14,
                lineHeight: 1.75,
                letterSpacing: '0.056em',
                padding: 15,
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                    padding: 24,
                },
                '& span': {
                    fontSize: '135%',
                    fontWeight: 'bold',
                    fontFamily: theme.headerFont,
                    float: 'right',
                    marginLeft: 32,
                    letterSpacing: '0.04em',
                    color: theme.palette.common.white,
                }
            },
            '& a.button': {
                width: '100%',
                margin: 0,
                fontFamily: theme.headerFont,
                boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                fontSize: 16,
                lineHeight: 1.2,
                fontWeight: 400,
                textTransform: 'uppercase',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: 4,
                textDecoration: 'none',
                marginRight: 15,
                minWidth: 0,
                textAlign: 'left',
                padding: '14px 20px',
                display: 'block',
                letterSpacing: '0.06em',
                maxWidth: 215,
                marginTop: 24,
                '&::after': {
                    width: 20,
                    height: 20,
                    content: '""',
                    backgroundImage: 'url(' + arrow + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    display: 'inline-block',
                    backgroundPosition: 'center',
                    marginLeft: 16,
                    float: 'right',
                },
            },
            '& .social-links': {
                marginTop: 24,
                '& a': {
                    width: '100%',
                    margin: 0,
                    fontFamily: theme.headerFont,
                    fontSize: 16,
                    lineHeight: 1.2,
                    fontWeight: 400,
                    textTransform: 'uppercase',
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    marginRight: 15,
                    minWidth: 0,
                    textAlign: 'left',
                    padding: '14px 20px',
                    display: 'block',
                    letterSpacing: '0.06em',
                    maxWidth: 215,
                    '& svg': {
                        display: 'inline-block',
                        float: 'right',
                        top: -4,
                    }
                },
            }
        },
    }
});