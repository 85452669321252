import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from "react-html-parser";
import {transformLink} from "../../../helpers";

export default function AccommodationTab({accommodation}) {
    
    if(accommodation===null) return null;

    return <Grid container spacing={10}>

        <Grid item xs={12} md={6}>
            <h3>Trading</h3>
            {ReactHtmlParser(accommodation.trading, {transform : transformLink})}
            <img src={accommodation.tradingImage} alt="" />
        </Grid>

        <Grid item xs={12} md={6}>
            <h3>Private</h3>
            {ReactHtmlParser(accommodation.overview, {transform : transformLink})}
            <img src={accommodation.overviewImage} alt="" />
        </Grid>

    </Grid>;
}