import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import TenancySlider from "./tenancySlider";
import GoBack from "../../GoBack";

const useStyles = makeStyles((theme) => styles(theme));

export default function TenancyComing({ name, location, gallery }) {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <GoBack fallback="/run-a-pub" />
      <Grid container spacing={10}>
        <Grid item xs={12} md={6} lg={5}>
          <h2>{name}</h2>
          <p className="ooh">{location}</p>
          <p>
            Full details on this upcoming vacancy are still being compiled.
            Please check back soon for more information.
          </p>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {gallery !== null && <TenancySlider images={gallery} />}
        </Grid>
      </Grid>
    </section>
  );
}
