export default theme => ({
  CardInfo: {
    position: 'absolute',
    top: 15,
    right: 0,
    fontSize: 11,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: theme.headerFont,
    display: 'inline-block',
    color: theme.palette.common.white,
    letterSpacing: '0.04em',
    padding: '8px 10px',
    backgroundColor: '#72B5CC',

    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      zIndex: 1,
      padding: '10px 20px',
      right: 'auto',
      left: 0,
      top: 0,
    }
  },
  CardLinkFeatured: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    width: '100%',
    letterSpacing: '0.04em',
    color: theme.palette.primary.main,
    fontFamily: theme.subHeaderFont,
    fontSize: 16,
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontWeight: 'bold',
    borderTop: '3px solid black',
    [theme.breakpoints.up('md')]: {
        padding: '1em 2em',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: '50%',
        width: 'auto',
    },
    '& img': {
      height: 15
    }
  },
  CardLabels: {
    fontSize: 11,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: theme.headerFont,
    display: 'inline-block',
    color: theme.palette.common.white,
    letterSpacing: '0.04em',
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      zIndex: 1,
      position: 'relative',
    },
    '& span': {
      padding: '8px 10px',
      display: 'inline-block',
      backgroundColor: '#72B5CC',
      [theme.breakpoints.up('md')]: {
        padding: '10px 20px',
      },
    }
  },
  CardLabel: {
    padding: '8px 18px',
    backgroundColor: theme.palette.primary.main
  },
  CardLabelNew: {
    display: 'inline-block',
    backgroundColor: '#72CE9B !important',
  },
  CardLabelComingSoon: {
    backgroundColor: '#5db7b7 !important',
  },
  CardLabelRecentlyLet: {
    backgroundColor: '#687084 !important',
  },
  CardLabelToBeDiscussed: {
    backgroundColor: '#4f7d84 !important',
  },
  CardLabelUnderOffer: {
    backgroundColor: '#b76261 !important',
  },
  CardLabelInvestmentPlanned: {
    backgroundColor: '#e09545 !important',
  },
  CardLabelFeatured: {
    backgroundColor: '#865F7F !important',
  },
})