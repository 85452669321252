import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import iconAccommodation from "../../../images/figures-accommodation.png";
import iconBeverages from "../../../images/figures-beverages.png";
import iconFood from "../../../images/figures-food.png";
import iconRent from "../../../images/figures-rent.png";
import Grid from "@material-ui/core/Grid/Grid";

const useStyles = makeStyles(theme => styles(theme));

export default function TenancyFigures({accommodation, beverages, food, rent, turnover}) {
    const classes = useStyles();
    return <section className={classes.root}>
        <h2>The Figures</h2>
        <Grid container spacing={3} className="figure-band">
            {accommodation !== null && 
                <Grid item xs={6} className="col accommodation">
                    <img src={iconAccommodation} alt="" />
                    <hr />
                    <p><span>Accommodation </span>£{accommodation}</p>
                </Grid>
            }
            {beverages !== null && 
                <Grid item xs={6} className="col beverages">
                    <img src={iconBeverages} alt="" />
                    <hr />
                    <p><span>Beverages </span>£{beverages}</p>
                </Grid>
            }
            {food !== null && 
                <Grid item xs={6} className="col food">
                    <img src={iconFood} alt="" />
                    <hr />
                    <p><span>Food </span>£{food}</p>
                </Grid>
            }
            {rent !== null && 
                <Grid item xs={6} className="col rent">
                    <img src={iconRent} alt="" />
                    <hr />
                    <p><span>Rent </span>£{rent}</p>
                </Grid>
            }
            {turnover !== null && 
                <Grid  item xs={12} className="col turnover">
                    <h3>Potential turnover</h3>
                    <hr />
                    <p><span></span>£{turnover}</p>
                </Grid>
            }
        </Grid>
  </section>;
}