export default theme => ({
    root: {
        position: 'relative',
        padding: '80px 0 50px',
        marginBottom: 70,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 120,
            paddingBottom: 100,
            marginBottom: 110,
        },
        '&::before': {
            backgroundColor: theme.palette.common.black,
            position: 'absolute',
            zIndex: -1,
            top: 0,
            bottom: 0,
            left: '50%',
            width: '100vw',
            transform: 'translateX(-50%)',
            content: '""',
        },
        '& h2': {
            fontSize: 24,
            color: theme.palette.common.white,
            letterSpacing: '0.056em',
            lineHeight: 1.25,
            marginBottom: 40,
            marginTop: 0,
            textTransform: 'uppercase',
            [theme.breakpoints.up('sm')]: {
                fontSize: 36,
                marginBottom: 70,
            },
            '&::before': {
                width: 40,
                height: 4,
                content: '""',
                display: 'block',
                marginBottom: 16,
                backgroundColor: theme.palette.primary.main,
            }
        },
        '& .figure-band': {
            '& .col': {
                [theme.breakpoints.up('sm')]: {
                    flex: '0 0 20%',
                    maxWidth: '20%',
                },
                '& img': {
                    width: 'auto',
                    height: 50,
                    marginBottom: 20,
                    display: 'block',
                    [theme.breakpoints.up('sm')]: {
                        height: 92,
                    },
                },
                '& hr': {
                    border: 'none',
                    borderTop: '1px solid ' + theme.palette.common.white,
                    margin: '10px 0',
                },
                '& h3': {
                    fontSize: 18,
                    lineHeight: 2,
                    color: theme.palette.common.white,
                    fontFamily: theme.bodyFont,
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    marginBottom: 10,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: 32,
                        lineHeight: 1.2,
                        height: 92,
                        marginTop: 0,
                        marginBottom: 20
                    },
                },
                '& p': {
                    fontFamily: theme.headerFont,
                    color: theme.palette.common.white,
                    fontWeight: 'bold',
                    lineHeight: 1.25,
                    letterSpacing: '0.056em',
                    fontSize: 24,
                    margin: 0,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: 32,
                    },
                    '& span': {
                        fontFamily: theme.bodyFont,
                        fontStyle: 'italic',
                        display: 'block',
                        height: 12,
                        fontSize: 12,
                        lineHeight: 1,
                        fontWeight: 'normal',
                        [theme.breakpoints.up('sm')]: {
                            fontSize: 14,
                            height: 14,
                        },
                    }
                }
            }
        }
    }
});