import React, { Suspense, lazy } from 'react';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from "react-html-parser";
import {transformLink} from "../../../helpers";
import { GatsbyImage } from "gatsby-plugin-image";

const Map = lazy(() => import('../../Pub/components/Map'))

export default function LocationTab({location}) {

    let image = null;

    if(location.overviewImage && location.overviewImage.mediaItemUrlSharp){
        if(location.overviewImage.mediaItemUrlSharp.childImageSharp){
            image = <GatsbyImage
                image={location.overviewImage.mediaItemUrlSharp.childImageSharp.gatsbyImageData}
                alt="" />
        } else {
            // Gifs do not have a childImageSharp
            image = <img
                src={location.overviewImage.mediaItemUrlSharp.publicURL}
                alt=""
            />
        }
    } else if(location.overviewImage) {
        // This is a preview image
        image = <div className="gatsby-image-wrapper">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={location.overviewImage.mediaItemUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }

    // Parse address
    let address = [];
    if(location.address.address1) address.push(location.address.address1);
    if(location.address.address2) address.push(location.address.address2);
    if(location.address.town) address.push(location.address.town);
    if(location.address.region) address.push(location.address.region);
    if(location.address.postcode) address.push(location.address.postcode);

    return <Grid container spacing={10}>

      <Grid item xs={12}>
          <h3>A Little more about us</h3>
          <div className="overview">
            {ReactHtmlParser(location.overview, {transform : transformLink})}
          </div>
          {image}
          <div className="location-info">
              <Suspense fallback={<div>Loading...</div>}>
                <Map className="map" lng={location.long} lat={location.lat} zoom={15} />
              </Suspense>
              <div className="address">
                <h4>{location.name}</h4>
                <p>{ReactHtmlParser(address.join(',<br />'))}</p>
              </div>
          </div>
      </Grid>

    </Grid>;
}