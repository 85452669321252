import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import TenancyComing from "../components/Tenancy/TenancyComing";
import TenancyData from "../components/Tenancy/TenancyData";
import TenancyOverview from "../components/Tenancy/TenancyOverview";
import TenancyFigures from "../components/Tenancy/TenancyFigures";
import TenancyTabs from "../components/Tenancy/TenancyTabs";
import { CgbContactCalloutBlock } from "../components/blocks/CgbContactCalloutBlock";

class PubTenancy extends Component {
  replacePlaceholders(text) {
    const {
      area,
      name,
      telephone,
      town,
      housenameShort,
      housenamePossesive,
      displayLocation,
    } = this.props.data.wpPub.PubData;
    if (text === null) {
      return null;
    }
    return text
      .replace("[area]", area)
      .replace("[name]", name)
      .replace("[telephone]", telephone)
      .replace("[displayLocation]", displayLocation)
      .replace("[town]", town)
      .replace("[housenameShort]", housenameShort)
      .replace("[housenamePosessive]", housenamePossesive);
  }

  render() {
    const data = this.props.data.allWp.nodes;
    const pub = this.props.data.wpPub.PubData;

    let dataComplete = true;

    if (
      pub.financials.annualRent === "" ||
      pub.financials.potentialFmtTurnover === "" ||
      pub.content.overview === "" ||
      pub.incomeSplit.accommodationIncome === "" ||
      pub.incomeSplit.beveragesIncome === "" ||
      pub.incomeSplit.foodIncome === "" ||
      pub.financials.annualRent === "" ||
      pub.financials.potentialFmtTurnover === ""
    ) {
      dataComplete = false;
    }

    let contactObject = {
      attributes: {
        className: "mb-0 green-buttons",
      },
      innerBlocks: [
        {
          attributes: {
            align: "right",
            backgroundColor: "primary",
            borderRadius: 4,
            className: "icon-form",
            gradient: null,
            linkTarget: "",
            placeholder: null,
            rel: "",
            text: "Enquiry today",
            textColor: "foreground_primary",
            title: "",
            url: "/contact-us/",
            __typename: "WpCoreButtonBlockAttributes",
          },
          innerBlock: [],
          name: "core/button",
          __typename: "WpCoreButtonBlock",
        },
        {
          attributes: {
            align: "right",
            backgroundColor: "primary",
            borderRadius: 4,
            className: "icon-mail",
            gradient: null,
            linkTarget: "",
            placeholder: null,
            rel: "",
            text: "Email us",
            textColor: "foreground_primary",
            title: "",
            url: "mailto:recruitment@frederic-robinson.co.uk",
            __typename: "WpCoreButtonBlockAttributes",
          },
          innerBlock: [],
          name: "core/button",
          __typename: "WpCoreButtonBlock",
        },
        {
          attributes: {
            align: "right",
            backgroundColor: "primary",
            borderRadius: 4,
            className: "icon-phone",
            gradient: null,
            linkTarget: "",
            placeholder: null,
            rel: "",
            text: "Call us",
            textColor: "foreground_primary",
            title: "",
            url: "tel:01616124112",
            __typename: "WpCoreButtonBlockAttributes",
          },
          innerBlock: [],
          name: "core/button",
          __typename: "WpCoreButtonBlock",
        },
        {
          attributes: {
            align: null,
            anchor: "",
            className: null,
            content: "Interested?",
            level: 2,
            textColor: null,
            __typename: "WpCoreHeadingBlockAttributes",
          },
          innerBlock: [],
          name: "core/heading",
          __typename: "WpCoreHeadingBlock",
        },
        {
          attributes: {
            align: null,
            backgroundColor: null,
            className: null,
            content:
              "Get in touch today and start your journey towards a fantastic career in this exciting industry.",
            direction: null,
            textColor: null,
            __typename: "WpCoreParagraphBlockAttributes",
          },
          innerBlock: [],
          name: "core/paragraph",
          __typename: "WpCoreParagraphBlock",
        },
      ],
    };

    return (
      <Layout
        meta={{}}
        path={this.props.pageContext.pagePath}
        title={""}
        themeOptions={data.themeOptions}
      >
        {!dataComplete && (
          <TenancyComing
            name={pub.name}
            location={pub.displayLocation}
            gallery={pub.galleryImages}
          />
        )}
        {dataComplete && (
          <TenancyData
            name={pub.name}
            location={pub.displayLocation}
            gallery={pub.galleryImages.filter(
              (image) => image.showInTenancyGallery
            )}
            rentPerWeek={pub.financials.weeklyRent}
            rentPerAnnum={pub.financials.annualRent}
            turnover={pub.financials.potentialFmtTurnover}
            agreementType={pub.agreementType}
            status={pub.tenancyStatus}
            featured={pub.featured}
          />
        )}
        {dataComplete && (
          <TenancyOverview
            overview={pub.content.overview}
            ooh={this.replacePlaceholders(pub.pubOoh)}
            downloads={pub.content.downloads}
            crmId={pub.crmId}
            facilityAccommodation={pub.facilityAccommodation}
            facilityBeerGarden={pub.facilityBeerGarden}
            facilityDogFriendly={pub.facilityDogFriendly}
            facilityFood={pub.facilityFood}
          />
        )}
        {dataComplete && (
          <TenancyFigures
            accommodation={pub.incomeSplit.accommodationIncome}
            beverages={pub.incomeSplit.beveragesIncome}
            food={pub.incomeSplit.foodIncome}
            rent={pub.financials.annualRent}
            turnover={pub.financials.potentialFmtTurnover}
          />
        )}
        {dataComplete && (
          <TenancyTabs
            location={{
              overview: pub.content.areaOverview,
              overviewImage: pub.content.areaImage,
              name: pub.name,
              address: {
                address1: pub.address1,
                address2: pub.address2,
                town: pub.town,
                region: pub.region,
                postcode: pub.postcode,
              },
              lat: pub.latitude,
              long: pub.longitude,
            }}
            accommodation={
              pub.content.accommodationOverview
                ? {
                    trading: pub.content.tradingAccommodation,
                    tradingImage: pub.content.tradingAccommodationImage,
                    overview: pub.content.accommodationOverview,
                    overviewImage: pub.content.accommodationOverviewImage,
                  }
                : null
            }
            barrelage={pub.barrelage}
            loi={{
              loi: pub.levelOfInvestment,
              turnover: pub.financials.potentialFmtTurnover,
            }}
            channels={{
              website: pub.externalHomepageUrl,
              facebook: pub.facebookHandle,
              twitter: pub.twitterHandle,
              instagram: pub.instagramHandle,
              image: pub.digitalChannelsImage,
            }}
          />
        )}
        <div className="disclaimer" style={{ marginBottom: 36 }}>
          <h4>DISCLAIMER</h4>
          <p style={{ fontSize: 12, lineHeight: 2 }}>
            <i>
              FMT barrelage: Please note that this assessment is based on the
              "fair maintainable trade" that can be achieved by a "reasonably
              efficient operator" and is not therefore a guarantee of volume or
              consequential sales or profit.
            </i>
          </p>
          <p style={{ fontSize: 12, lineHeight: 2 }}>
            <i>
              Similarly Historic performance, whilst a significant factor to
              consider, is not a guarantee of future performance of a pub and
              you should bear this in mind in your assessment of trading
              potential.
            </i>
          </p>
        </div>
        <CgbContactCalloutBlock
          attributes={contactObject.attributes}
          innerBlocks={contactObject.innerBlocks}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String!) {
    wpPub(id: { eq: $id }) {
      id
      PubData {
        crmId
        name
        agreementType
        area
        displayLocation
        facilityAccommodation
        facilityBeerGarden
        facilityDogFriendly
        facilityFood
        featured
        financials {
          weeklyRent
          annualRent
          potentialFmtTurnover
        }
        galleryImages {
          image
          showInTenancyGallery
        }
        content {
          overview
          downloads {
            file
            title
            externalLink
          }
          areaOverview
          areaImage {
            mediaItemUrl
          }
          tradingAccommodation
          tradingAccommodationImage
          accommodationOverview
          accommodationOverviewImage
        }
        pubOoh
        incomeSplit {
          accommodationIncome
          beveragesIncome
          foodIncome
        }
        address1
        address2
        town
        region
        postcode
        latitude
        longitude
        googlePlaceId
        barrelage {
          fmtBarrelage
          currentMatBarrelage
          oneYearAgoMatBarrelage
          twoYearAgoMatBarrelage
          threeYearAgoMatBarrelage
          barrelageImage
          barrelageNote
        }
        levelOfInvestment {
          fourWeeksRent
          tradingDeposit
          professionalFeesAndInduction
          stockAndGlassware
          subTotal
          fixturesAndFittings
          levelOfInvestmentImage
        }
        externalHomepageUrl
        facebookHandle
        twitterHandle
        instagramHandle
        digitalChannelsImage
        tenancyStatus
      }
    }
    allWp {
      nodes {
        themeOptions {
          accommodationActive
          roomBookingCTAText
          roomBookingImage
          roomBookingImageText
          roomBookingLink
          tableBookingAccountId
          tableBookingActive
          tableBookingBrandId
          tableBookingCTAText
          tableBookingImage
          tableBookingImageText
          tableBookingRestaurantId
          liveResSiteId
          ThemeOptions {
            siteLogo {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export default PubTenancy;
