import backArrow from "../../../images/arrow-prev-black.png";
import iconEmail from "../../../images/icon-email.png";
import iconPhone from "../../../images/icon-phone.png";
import iconForm from "../../../images/icon-form.png";
import arrow from "../../../images/right-arrow.png";
import sliderArrow from "../../../images/slider-arrow-green.png";
import expandIcon from "../../../images/expand-arrows-alt.png";
import iconEmailBlack from "../../../images/icon-email-black.png";
import iconPhoneBlack from "../../../images/icon-phone-black.png";
import iconFormBlack from "../../../images/icon-form-black.png";
import brass from "../../../images/brass-texture-green.png";

export default (theme) => ({
  root: {
    "& .MuiGrid-spacing-xs-10": {
      marginTop: 0,
      marginBottom: 0,
      "& > .MuiGrid-item": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    "& .go-back": {
      backgroundColor: "transparent",
      border: "none",
      color: theme.palette.common.black,
      fontFamily: theme.headerFont,
      fontSize: 14,
      letterSpacing: "0.05em",
      lineHeight: "18px",
      textTransform: "uppercase",
      padding: 0,
      outline: "none !important",
      paddingLeft: 28,
      backgroundImage: "url(" + backArrow + ")",
      backgroundPosition: "center left",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      transition: "opacity .3s ease-in-out",
      marginTop: 16,
      [theme.breakpoints.up("md")]: {
        marginBottom: 36,
        marginTop: 40,
      },
      "&:hover, &:focus": {
        opacity: 0.8,
      },
    },
    "& h2": {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: theme.headerFont,
      fontSize: 24,
      marginTop: 30,
      marginBottom: 10,
      lineHeight: 1.25,
      display: "block",
      letterSpacing: "0.056em",
      [theme.breakpoints.up("md")]: {
        fontSize: 32,
        marginTop: 0,
      },
    },
    "& .ooh": {
      letterSpacing: "0.056em",
      lineHeight: 1.5,
      marginBottom: 24,
      fontStyle: "italic",
      fontFamily: theme.bodyFont,
      display: "block",
      marginTop: 0,
      fontSize: 16,
      [theme.breakpoints.up("md")]: {
        marginBottom: 30,
        fontSize: 24,
      },
    },
    "& .cost-breakdowns": {
      backgroundImage: "url(" + brass + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: 8,
      width: "calc(100% + 34px)",
      margin: "0 -17px",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        margin: 0,
        padding: 10,
      },
      "&.desktop": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },
      "&.mobile": {
        marginTop: 50,
        marginBottom: -30,
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
      "& .wrap": {
        backgroundColor: theme.palette.common.white,
        padding: "8px 8px 32px 8px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        [theme.breakpoints.up("md")]: {
          padding: "8px 30px 32px 30px",
        },
        "& .rent, & .turnover, & .agreement": {
          fontWeight: "bold",
          fontSize: 16,
          letterSpacing: "0.04em",
          color: theme.palette.common.black,
          fontFamily: theme.headerFont,
          lineHeight: 1.2,
          borderBottom: "1px solid " + theme.palette.common.black,
          flex: "0 0 calc(50% - 16px)",
          margin: "0 8px",
          marginTop: 24,
          [theme.breakpoints.up("md")]: {
            flex: "0 0 calc(50% - 24px)",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: 24,
          },
          "& i": {
            fontSize: 12,
            [theme.breakpoints.up("md")]: {
              fontSize: 16,
            },
          },
          "& span": {
            color: "#0e0e0e",
            fontSize: 11,
            lineHeight: 1.75,
            fontStyle: "italic",
            letterSpacing: "0.056em",
            margin: 0,
            fontFamily: theme.bodyFont,
            fontWeight: "normal",
            display: "block",
            width: "100%",
            [theme.breakpoints.up("md")]: {
              marginTop: 8,
              marginBottom: 5,
            },
          },
        },
      },
    },
    "& .interested": {
      marginBottom: 36,
      "&.desktop": {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
        "&.desktop": {
          display: "block",
        },
      },
      "& h3": {
        fontSize: 24,
        lineHeight: 1.25,
        textTransform: "uppercase",
        letterSpacing: "0.04em",
        marginTop: 100,
        marginBottom: 20,
        [theme.breakpoints.up("md")]: {
          marginTop: 40,
        },
        "&::before": {
          width: 40,
          height: 4,
          content: '""',
          display: "block",
          marginBottom: 16,
          backgroundColor: theme.palette.primary.main,
        },
      },
      "& a.button": {
        width: "calc(33.333% - 10px)",
        margin: 0,
        fontFamily: theme.headerFont,
        boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
        fontSize: 16,
        lineHeight: 1.2,
        fontWeight: 400,
        textTransform: "uppercase",
        backgroundColor: "#72ce9b",
        color: theme.palette.common.black,
        display: "inline-flex",
        borderRadius: 4,
        textDecoration: "none",
        letterSpacing: "0.06em",
        marginRight: 15,
        flexDirection: "column-reverse",
        minWidth: 0,
        maxWidth: "calc(100% - 50px)",
        textAlign: "center",
        padding: "20px 30px",
        alignItems: "center",
        justifyContent: "center",
        transition: ".3s background-color ease-in-out",
        [theme.breakpoints.up("md")]: {
          maxWidth: 115,
          marginRight: 26,
          width: "calc(33.333% - 18px)",
        },
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.main + " !important",
          color: theme.palette.primary.contrastText,
        },
        "&::after": {
          width: 20,
          height: 20,
          content: '""',
          backgroundImage: "url(" + arrow + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          display: "inline-block",
          backgroundPosition: "center",
          marginBottom: 10,
          marginLeft: 0,
        },
        "&.email::after": {
          backgroundImage: "url(" + iconEmailBlack + ") !important",
        },
        "&.email:hover:after, &.email:focus:after": {
          backgroundImage: "url(" + iconEmail + ") !important",
        },
        "&.phone::after": {
          backgroundImage: "url(" + iconPhoneBlack + ") !important",
        },
        "&.phone:hover:after, &.phone:focus:after": {
          backgroundImage: "url(" + iconPhone + ") !important",
        },
        "&.form": {
          marginRight: 0,
        },
        "&.form::after": {
          backgroundImage: "url(" + iconFormBlack + ") !important",
        },
        "&.form:hover:after, &.form:focus:after": {
          backgroundImage: "url(" + iconForm + ") !important",
        },
      },
    },
    "& .modal": {
      position: "fixed",
      backgroundColor: "rgba(0,0,0,0.85)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 99999,
      top: "50%",
      left: "50%",
      right: "50%",
      bottom: "50%",
      opacity: 0,
      "&[data-open='true']": {
        opacity: 1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        "& img": {
          opacity: 1,
        },
      },
      "& button.close-modal": {
        position: "absolute",
        top: 32,
        right: 32,
        padding: 0,
        background: "transparent",
        border: "none",
      },
      "& button.next-slide, & button.prev-slide": {
        position: "absolute",
        top: 'calc(50% - 22px)',
        backgroundImage: "url(" + sliderArrow + ")",
        textIndent: -999999,
        width: 44,
        height: 44,
        backgroundSize: '20px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        backgroundColor: 'transparent',
      },
      "& button.prev-slide": {
        left: 12,
        transform: 'rotate(180deg)'
      },
      "& button.next-slide": {
        right: 12,
      },
      "& > img": {
        maxHeight: "calc(100% - 64px)",
        maxWidth: "calc(100% - 64px)",
        // transition: "opacity .5s ease-in-out",
        opacity: 0,
      },
    },
    "& .slick-slider": {
      "& .image-slide": {
        psoition: "relative",
        "& > button": {
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          position: "relative",
          "&:after": {
            content: '""',
            display: "block",
            backgroundImage: "url(" + expandIcon + ")",
            width: 32,
            height: 32,
            backgroundSize: "cover",
            backgroundPosition: "cover",
            position: "absolute",
            top: 12,
            right: 12,
            zIndex: 1,
          },
        },
      },
      "& .slick-dots": {
        width: 48,
        bottom: "-32%",
        left: "calc(50% - 24px)",
        [theme.breakpoints.up("md")]: {
          right: 16,
          left: "auto",
          bottom: 44,
        },
        "&::after": {
          position: "absolute",
          left: 24,
          width: 1,
          height: 20,
          backgroundColor: theme.palette.common.black,
          content: '""',
          top: 0,
          transformOrigin: "50% 50%",
          transform: "rotate(15deg)",
          [theme.breakpoints.up("md")]: {
            backgroundColor: theme.palette.common.white,
          },
        },
        "& li": {
          position: "absolute",
          top: 0,
          left: 0,
          margin: 0,
          "& button": {
            "&::before": {
              display: "none",
            },
            padding: 0,
            fontSize: 17,
            fontWeight: "bold",
            fontFamily: theme.headerFont,
            textAlign: "center",
            width: 20,
            color: "transparent",
          },
          "&.slick-active": {
            "& button": {
              color: theme.palette.common.black,
              [theme.breakpoints.up("md")]: {
                color: theme.palette.common.white,
              },
            },
          },
          "&:last-of-type button": {
            textShadow: "28px 0 0 " + theme.palette.common.black,
            width: 48,
            paddingRight: 28,
            [theme.breakpoints.up("md")]: {
              textShadow: "28px 0 0 " + theme.palette.common.white,
            },
          },
        },
      },
    },
    "& .slick-slider.slider-nav": {
      marginTop: 4,
      paddingLeft: 26,
      paddingRight: 26,
      [theme.breakpoints.up("md")]: {
        paddingLeft: 50,
        paddingRight: 50,
      },
      "& .slick-slide": {
        padding: "0 4px",
      },
      "& .slick-next": {
        width: 18,
        height: 18,
        backgroundImage: "url(" + sliderArrow + ") !important",
        backgroundSize: "contain !important",
        backgroundPosition: "center !important",
        backgroundRepeat: "no-repeat !important",
        right: 0,
        zIndex: 10,
        transform: "translateY(-50%)",
        [theme.breakpoints.up("md")]: {
          right: 12,
        },
        "&::before": {
          display: "none",
        },
        "&.slick-disabled": {
          opacity: 0.25,
        },
      },
      "& .slick-prev": {
        width: 18,
        height: 18,
        backgroundImage: "url(" + sliderArrow + ") !important",
        backgroundSize: "contain !important",
        backgroundPosition: "center !important",
        backgroundRepeat: "no-repeat !important",
        left: 0,
        transform: "translateY(-50%) rotate(180deg)",
        zIndex: 10,
        [theme.breakpoints.up("md")]: {
          left: 12,
        },
        "&::before": {
          display: "none",
        },
        "&.slick-disabled": {
          opacity: 0.25,
        },
      },
    },
  },
  CardInfo: {
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: 11,
    fontWeight: 700,
    textTransform: "uppercase",
    fontFamily: theme.headerFont,
    display: "inline-block",
    color: theme.palette.common.white,
    letterSpacing: "0.04em",
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      zIndex: 1,
      right: "auto",
      left: 0,
      top: 0,
    },
  },
  TenancySlider: {
    position: "relative",
  },
});
