import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import TenancySlider from "./tenancySlider";
import GoBack from "../../GoBack";
import Link from "../../Link";

const useStyles = makeStyles((theme) => styles(theme));

export default function TenancyData({
  name,
  location,
  gallery,
  status,
  featured,
  rentPerWeek,
  rentPerAnnum,
  turnover,
  agreementType,
}) {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <GoBack fallback="/run-a-pub" />
      <Grid container spacing={10}>
        <Grid item xs={12} md={6} lg={5}>
          <h2>{name}</h2>
          <p className="ooh">{location}</p>
          <div className="cost-breakdowns desktop">
            <div className="wrap">
              {rentPerWeek && (
                <p className="rent">
                  £{rentPerWeek}
                  <span>Weekly Rent</span>
                </p>
              )}
              <p className="rent">
                £{rentPerAnnum}
                <span>Yearly Rent</span>
              </p>
              <p className="agreement">
                {agreementType}
                <span>Agreement type</span> 
              </p>
              <p className="turnover">
                £{turnover}
                <span>Potential turnover</span>
              </p>
            </div>
          </div>
          <div className="interested desktop">
            <h3>I am interested</h3>
            <Link className="button phone" to="tel:01616124112">
              <span>Call us</span>
            </Link>
            <Link
              className="button email"
              to="mailto:recruitment@frederic-robinson.co.uk"
            >
              <span>Email us</span>
            </Link>
            <Link className="button form" href="/contact-us/">
              <span>Enquire Today</span>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {gallery !== null && (
            <TenancySlider images={gallery} classes={classes} status={status} featured={featured} />
          )}
          <div className="cost-breakdowns mobile">
            <div className="wrap">
              {rentPerWeek && (
                <p className="rent">
                  £{rentPerWeek}
                  <span>Weekly Rent</span>
                </p>
              )}
              <p className="rent">
                £{rentPerAnnum}
                <span>Yearly Rent</span>
              </p>
              <p className="agreement">
                {agreementType}
                <span>Agreement type</span> 
              </p>
              <p className="turnover">
                £{turnover}
                <span>Potential turnover</span>
              </p>
            </div>
          </div>
          <div className="interested mobile">
            <h3>I am interested</h3>
            <Link className="button phone" to="tel:01616124112">
              <span>Call us</span>
            </Link>
            <Link
              className="button email"
              to="mailto:recruitment@frederic-robinson.co.uk"
            >
              <span>Email us</span>
            </Link>
            <Link className="button form" href="/contact-us/">
              <span>Enquire Today</span>
            </Link>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}
