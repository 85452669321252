import React from 'react';
import Grid from '@material-ui/core/Grid';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Link from "../../Link";

export default function ChannelsTab({channels}) {

    return <Grid container spacing={10}>

        <Grid item xs={12} md={6}>
            <h3>Digital Channels</h3>
            <p>In todays world of running a pub, an active digital presence is part of great success and customer engagement.</p>
            {channels.website !== null &&
            <Link className="button" to={channels.website} target="_blank" rel="noreferrer">Website</Link>
            }
            <div className="social-links">
                {channels.facebook !== null &&
                <Link to={"https://www.facebook.com/" + channels.facebook} target="_blank" rel="noreferrer">Facebook <FacebookIcon/></Link>
                }
                {channels.twitter !== null &&
                <Link to={"https://www.twitter.com/" + channels.twitter} target="_blank" rel="noreferrer">Twitter <TwitterIcon/></Link>
                }
                {channels.instagram !== null &&
                <Link to={"https://www.instagram.com/" + channels.instagram} target="_blank" rel="noreferrer">Instagram <InstagramIcon/></Link>
                }
            </div>
        </Grid>

        <Grid item xs={12} md={6}>
            <img src={channels.image} alt="" />
        </Grid>

    </Grid>;
}