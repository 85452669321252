import linkArrow from "../../../images/link-arrow.png";
import arrow from "../../../images/right-arrow-black.png";
import arrowWhite from "../../../images/right-arrow.png";

export default (theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginTop: 90,
      marginBottom: 130,
    },
    "& .MuiGrid-spacing-xs-10": {
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up("md")]: {
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      },
      "& > .MuiGrid-item": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    "& .ooh-wrap": {
      marginTop: 50,
      [theme.breakpoints.up("md")]: {
        marginTop: 85,
        display: "flex",
        flexDirection: "column-reverse",
      },
      "& .pub-ooh": {
        marginTop: 36,
        fontSize: 24,
        fontStyle: "italic",
        color: theme.palette.primary.main,
        letterSpacing: "0.056em",
        lineHeight: 1.75,
        [theme.breakpoints.up("md")]: {
          marginTop: 0,
          marginBottom: 75,
        },
      },
      "& .download-link": {
        fontSize: 16,
        color: theme.palette.primary.main,
        letterSpacing: "0.06em",
        lineHeight: 1.2,
        marginBottom: 12,
        display: "block",
        textTransform: "uppercase",
        textDecoration: "none",
        fontFamily: theme.headerFont,
        transition: "color .3s ease-in-out",
        [theme.breakpoints.up("md")]: {
          marginTop: 24,
          marginBottom: 0,
        },
        "&::after": {
          width: 26,
          height: 20,
          display: "inline-block",
          verticalAlign: "bottom",
          marginLeft: 15,
          backgroundImage: "url(" + linkArrow + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          content: '""',
          transition: "margin-left .3s ease-in-out",
        },
        "&:hover, &:focus": {
          color: theme.palette.primary.dark,
          "&::after": {
            marginLeft: 25,
          },
        },
      },
    },
    "& .overview": {
      marginBottom: 110,
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },
      "& h2": {
        fontWeight: "bold",
        textTransform: "uppercase",
        fontFamily: theme.headerFont,
        fontSize: 24,
        marginTop: 36,
        marginBottom: 36,
        lineHeight: 1.25,
        display: "block",
        letterSpacing: "0.056em",
        [theme.breakpoints.up("md")]: {
          marginTop: 0,
          marginBottom: 30,
          fontSize: 35,
          letterSpacing: "0.06em",
        },
        "&::before": {
          width: 40,
          height: 4,
          content: '""',
          display: "block",
          marginBottom: 16,
          backgroundColor: theme.palette.primary.main,
        },
      },
      "& h3": {
        color: theme.palette.primary.main,
        fontWeight: "normal",
        marginBottom: 20,
        fontStyle: "italic",
        fontFamily: "Libre Baskerville",
        fontSize: 18,
        [theme.breakpoints.up("md")]: {
          fontSize: 24,
        },
      },
      "& .facilities": {
        marginBottom: 100,
        [theme.breakpoints.up("md")]: {
          marginBottom: 50,
        },
        "& span": {
          fontFamily: "Libre Baskerville",
          fontSize: 18,
          display: "block",
          lineHeight: "32px",
          marginBottom: 20,
          [theme.breakpoints.up("md")]: {
            display: "inline-block",
            marginRight: 40,
          },
          "& img": {
            width: 32,
            marginRight: 10,
            display: "inline-block",
            verticalAlign: "bottom",
          },
        },
      },
      "& .downloads": {
        [theme.breakpoints.up("md")]: {
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        },
        "& a": {
          margin: 0,
          marginBottom: 32,
          fontFamily: theme.headerFont,
          boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
          fontSize: 16,
          lineHeight: 1.2,
          fontWeight: 400,
          textTransform: "uppercase",
          backgroundColor: "#72ce9b",
          color: theme.palette.common.black,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 4,
          textDecoration: "none",
          letterSpacing: "0.06em",
          marginRight: 15,
          width: "100%",
          padding: "12px 16px",
          transition: ".3s background-color ease-in-out",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 30%",
            padding: "15px 20px",
          },
          "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main + " !important",
            color: theme.palette.primary.contrastText,
            "&:after": {
              backgroundImage: "url(" + arrowWhite + ")",
            },
          },
          "&::after": {
            width: 15,
            height: 15,
            content: '""',
            backgroundImage: "url(" + arrow + ")",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: "inline-block",
            backgroundPosition: "center",
            marginLeft: 0,
          },
        },
      },
      "& p": {
        fontSize: 14,
        lineHeight: 2,
        letterSpacing: "0.024em",
        [theme.breakpoints.up("md")]: {
          fontSize: 16,
          letterSpacing: "0.032em",
        },
      },
    },
  },
});
